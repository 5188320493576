import { writable, readable } from "svelte/store";

export const user = writable({
  role: "guest",
  events: {},
});

export const tickerNews = writable({});

export const geoFilter = writable({});

export const checkScreen = () => {
  if (typeof window === "undefined") {
    return readable(null, () => {});
  }
  const breakpoint = "850px";
  const mq = window.matchMedia(`(min-width: ${breakpoint})`);
  return readable(mq.matches, (set) => {
    const handleChange = () => set(mq.matches);
    mq.addListener(handleChange);
    return () => mq.removeListener(handleChange);
  });
};

export const viewportWH = writable({});

export const breakpoints = readable({});

export const totalEventsListColumns = writable({});
