<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const informHamburgerWasClicked = () => dispatch("hamburgerWasClicked");
</script>

<style>
  .frame {
    display: inline-flex;
    flex-direction: column;
    padding: 3px;
  }
  .slice {
    width: 35px;
    height: 5px;
    background-color: black;
    margin: 3px 0;
  }
</style>

<div class="frame" on:click={informHamburgerWasClicked}>
  <div class="slice" />
  <div class="slice" />
  <div class="slice" />
</div>
