<script>
  import SiteHeader from "../components/layout/SiteHeader/index.svelte";

  import { viewportWH } from "../components/stores";

  export let segment;
</script>

<style>
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--navbar-ht);
    z-index: 2;
  }
  .slot {
    position: fixed;
    top: var(--navbar-ht);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
  }
</style>

<svelte:head>
  <title>TrackMeetingsInfo - Where it's Athletics</title>
</svelte:head>
<div class="navbar">
  <SiteHeader {segment} />
</div>

<div
  class="slot"
  bind:clientWidth={$viewportWH.w}
  bind:clientHeight={$viewportWH.h}>
  <slot />
</div>
