<script>
    export let segments;
    export let segment;
</script>

<style>
    .NavigationBar {
        display: inline-flex;
    }
    .NavigationBar__link {
        display: inline-block;
        text-align: right;
        padding: 0 10px;
        cursor: pointer;
        color: black;
        text-decoration: none;
    }
    .NavigationBar__link:hover {
        color: #4951d0;
        transition: color 0.3s ease-in;
    }
    .NavigationBar__link--isSelected {
        color: rgb(226, 163, 4);
    }
</style>

<div class="NavigationBar">
    {#each segments as { id, title, url }}
        <a href={url}>
            <div
                class="NavigationBar__link"
                class:NavigationBar__link--isSelected={segment === id}
          >
                {title.eng}
            </div>
        </a>
    {/each}
</div>
