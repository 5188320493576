<script>
    import Hamburger from "../../ui/Hamburger.svelte";

    import { slide } from "svelte/transition";

    import screenfull from "screenfull";

    import { user } from "../../stores";

    export let segments;
    export let segment;
    let isDrawerOpened = false;

    const toggleDrawerOpened = () => {
        isDrawerOpened = !isDrawerOpened;
    };
    const closeDrawer = () => {
        isDrawerOpened = false;
    };

    const toggleFullScreen = () => {
        screenfull.toggle();
    };
</script>

<style>
    .NavigationDrawer {
    }

    .NavigationDrawer__toggleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
    }
    .NavigationDrawer__toggleButton--close {
        background-image: url("/icons/close2.png");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .NavigationDrawer__drawer {
        position: fixed;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        left: 0;
        right: 0;
        top: var(--navbar-ht);
        bottom: 0px;
        background-color: rgba(255, 255, 255, 0.75);
    }
    .NavigationDrawer__drawer--isOpened {
        visibility: visible;
    }
    .NavigationDrawer__link {
        color: white;
        font-size: 26px;
        width: 100%;
        text-align: center;
        margin: 0 0 5px 0;
        padding: 5px;
        background: #4951d0;
    }
    .NavigationDrawer__link--isSelected {
        color: gold;
    }
</style>

<div class="NavigationDrawer">
    <div
        class="NavigationDrawer__toggleButton"
        class:NavigationDrawer__toggleButton--close={isDrawerOpened}
        on:click={toggleDrawerOpened}>
        {#if !isDrawerOpened}
            <Hamburger />
        {/if}
    </div>
    <div
        class="NavigationDrawer__drawer"
        class:NavigationDrawer__drawer--isOpened={isDrawerOpened}
        transition:slide
        on:click={closeDrawer}>
        {#each segments as { id, title, url }}
            <a href={url}>
                <div
                    class="NavigationDrawer__link"
                    class:NavigationDrawer__link--isSelected={segment === id}>
                    {title.eng}
                </div>
            </a>
        {/each}
        {#if screenfull.isEnabled}
            <div class="NavigationDrawer__link" on:click={toggleFullScreen}>
                toggle fullscreen
            </div>
        {/if}
    </div>
</div>
