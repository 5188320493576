<script>
  // import NewsTicker from "./NewsTicker.svelte";
  import NavigationMenu from "./NavigationMenu.svelte";
  import NavigationDrawer from "./NavigationDrawer.svelte";

  import { viewportWH } from "../../stores";

  export let segment;
  let segments = [
    { id: undefined, title: { pp: "eng", eng: "search" }, url: "." },
    { id: "howto", title: { pp: "eng", eng: "how to" }, url: "howto" },
    { id: "about", title: { pp: "eng", eng: "about" }, url: "about" },
     { id: "profile", title: { pp: "eng", eng: "profile" }, url: "profile" },
  ];
</script>

<div class="SiteHeader">
  <a href="."><div class="SiteHeader__logo" /></a>
  <div class="SiteHeader__navigation">
    {#if $viewportWH.w > 1000}
      <NavigationMenu {segments} {segment} />
    {:else}
      <NavigationDrawer {segments} {segment} />
    {/if}
  </div>
</div>

<style>
  :root {
    --navbar-ht: 50px;
  }
  .SiteHeader {
    height: var(--navbar-ht);
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  .SiteHeader__logo {
    height: var(--navbar-ht);
    width: min(45vw, 210px);
    background-image: url("/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    margin-right: 10px;
  }
  .SiteHeader__navigation {
    flex: 0 0 auto;
  }
</style>
